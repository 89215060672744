import { getFullOnboardingDataFromLocal } from '~/helpers/getFullOnboardingDataFromLocal';
import { ONBOARDING_STEPS_METADATA } from '~/constants/onboardingStepsMetadata';
import EVENTS from '~/constants/events';

export default defineNuxtRouteMiddleware(async (to) => {
  // Check if we've reached the onboarding limit
  const { allowed } = await $fetch('/api/onboarding/check-throttle');
  if (!allowed) {
    return navigateTo({ name: 'member-waitlist' });
  }

  if (import.meta.server) {
    return;
  }

  const { $trackEvent } = useNuxtApp();

  const onboardingDataFromLocal = getFullOnboardingDataFromLocal();
  const user = useCurrentUser();

  if (!onboardingDataFromLocal?.data) {
    return;
  }

  const { isCompleted, incompleteStepId, data } = onboardingDataFromLocal;

  if (to.name === 'member-onboarding-start' && isCompleted) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'sign_up_sampler',
    });
    onNuxtReady(() => {
      navigateTo({ name: user.value ? 'home' : 'member-signup' });
    });
    return;
  }

  if (isCompleted) {
    return;
  }

  if (!incompleteStepId) {
    return;
  }

  const incompleteStepRoute = {
    name: 'member-onboarding-question',
    params: { id: incompleteStepId },
  };
  const incompleteStepRouteFullPath = `/members/onboarding/${incompleteStepId}`;

  if (to.name === 'member-signup' && !isCompleted) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'survey_step_sampler_onboarding',
    });
    onNuxtReady(() => {
      navigateTo(incompleteStepRoute);
    });
    return;
  }

  if (
    to.name === 'member-onboarding-start' &&
    !isCompleted &&
    Object.keys(data).length > 0
  ) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'survey_step_sampler_onboarding',
    });
    onNuxtReady(() => {
      navigateTo(incompleteStepRoute);
    });
    return;
  }

  const currentStepId = to.params.id;

  if (!currentStepId) {
    return;
  }

  const uniqueOnboardingStepsIds = Array.from(
    new Set(ONBOARDING_STEPS_METADATA.map((step) => step.id))
  );

  const currentStepIndex = uniqueOnboardingStepsIds.findIndex(
    (item) => item === currentStepId
  );
  const incompleteStepIndex = uniqueOnboardingStepsIds.findIndex(
    (item) => item === incompleteStepId
  );

  if (
    to.fullPath !== incompleteStepRouteFullPath &&
    currentStepIndex > incompleteStepIndex
  ) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'survey_step_sampler_onboarding',
    });
    onNuxtReady(() => {
      navigateTo(incompleteStepRoute);
    });
  }
});
